import { Fragment } from "react"
import styled from "styled-components"

import { Pager } from "src/components/Pager/Pager"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import {
  useDeleteNoiseMonitoringPreset,
  usePostNoiseMonitoringPresets,
} from "src/data/profileSettings/queries/monitoringPresetQueries"
import { TFetchNoiseMonitoringPresetsResponse } from "src/data/profileSettings/types/monitoringPresetTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { GridTable } from "src/ui/GridTable/GridTable"
import { MoreButton } from "src/ui/GridTable/MoreButton"
import CopyIcon from "src/ui/icons/copy.svg"
import InfoIcon from "src/ui/icons/important-outlined.svg"
import EditIcon from "src/ui/icons/pen-outlined.svg"
import TrashIcon from "src/ui/icons/trash.svg"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MCard } from "src/ui/MCard/MCard"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

type TPreset = TFetchNoiseMonitoringPresetsResponse["profiles"][number]
type NoiseMonitoringPresetTableProps = {
  presets: TFetchNoiseMonitoringPresetsResponse["profiles"] | undefined
  presetsTotalCount?: number
  setOffset: (offset: number) => void
  limit: number
  offset: number
}

export function NoiseMonitoringPresetTable({
  presets,
  presetsTotalCount,
  setOffset,
  limit,
  offset,
}: NoiseMonitoringPresetTableProps) {
  const { t, langKeys } = useTranslate()
  const { navigate } = useRouter()

  const { orgId } = useOrganization()

  const postNoiseMonitoringPresets = usePostNoiseMonitoringPresets({ orgId })
  const deleteNoiseMonitoringPresets = useDeleteNoiseMonitoringPreset()

  function handleEdit(id: string) {
    navigate(Routes.SettingsPresetsNoiseEdit.location(id))
  }

  function handleDuplicate(data: TPreset) {
    postNoiseMonitoringPresets.mutate({
      ...data,
      name: `${data.name} ${t(langKeys.copy_noun)}`,
    })
  }

  function handleDelete(id: string) {
    deleteNoiseMonitoringPresets.mutate({ presetId: id, orgId })
  }

  const loading =
    deleteNoiseMonitoringPresets.isLoading ||
    postNoiseMonitoringPresets.isLoading

  const rows =
    presets?.map((preset) => {
      const hasRentalUnits = preset.number_of_homes > 0

      return (
        <Fragment key={preset.id}>
          <Cell $loading={loading}>
            <InternalLink
              to={Routes.SettingsPresetsNoiseEdit.location(preset.id)}
            >
              {preset.name}
            </InternalLink>
          </Cell>
          <Cell $loading={loading}>
            {hasRentalUnits ? (
              preset.number_of_homes +
              " " +
              t(langKeys.home, {
                count: preset.number_of_homes,
              }).toLocaleLowerCase()
            ) : (
              <NoRentalUnitsBox>
                <InfoIcon width={18} height={18} />
                {t(langKeys.presets_no_rental_units_using)}
              </NoRentalUnitsBox>
            )}
          </Cell>
          <Cell $loading={loading}>
            <PresetOptions
              preset={preset}
              onEdit={() => handleEdit(preset.id)}
              onDuplicate={handleDuplicate}
              onDelete={handleDelete}
              nbrPresets={presets.length}
            />
          </Cell>
        </Fragment>
      )
    }) ?? []

  const mobileRows =
    presets?.map((preset) => {
      return (
        <MobileCard
          key={preset.id}
          preset={preset}
          nbrPresets={presets.length}
          loading={loading}
          callbacks={{
            onEdit: () => handleEdit(preset.id),
            onDuplicate: handleDuplicate,
            onDelete: handleDelete,
          }}
        />
      )
    }) ?? []

  return (
    <div>
      <GridTable
        templateColumns="1fr 3fr auto"
        header={[
          <div key="1">{t(langKeys.presets_create_new_preset_name)}</div>,
          <div key="2">{t(langKeys.presets_applies_to)}</div>,
          <div key="3"></div>,
        ]}
        rows={rows}
        mobileRows={mobileRows}
      />

      <Pager
        limit={limit}
        offset={offset}
        setOffset={setOffset}
        totalCount={presetsTotalCount ?? 0}
      />
    </div>
  )
}

type TPResetOptionsCallbacks = {
  onEdit: () => void
  onDuplicate: (d: TPreset) => void
  onDelete: (id: string) => void
}

type PresetOptionsProps = TPResetOptionsCallbacks & {
  preset: TPreset
  nbrPresets: number
}

function PresetOptions({
  preset,
  nbrPresets,
  onDuplicate,
  onEdit,
  onDelete,
}: PresetOptionsProps) {
  const { t, langKeys } = useTranslate()

  return (
    <MoreButton
      menuItems={[
        {
          contents: (
            <MenuContentBox>
              <EditIcon /> {t(langKeys.edit)}
            </MenuContentBox>
          ),
          key: "edit",
          onClick: onEdit,
        },
        {
          contents: (
            <MenuContentBox>
              <CopyIcon /> {t(langKeys.duplicate)}
            </MenuContentBox>
          ),
          key: "duplicate",
          onClick: () => onDuplicate(preset),
        },
        {
          contents: (
            <MenuContentBox color="emergency">
              <TrashIcon /> {t(langKeys.delete)}
            </MenuContentBox>
          ),
          key: "delete",
          onClick: () => onDelete(preset.id),
          hidden: nbrPresets < 2 || preset.number_of_homes > 0,
        },
      ]}
    />
  )
}

function MobileCard({
  loading,
  preset,
  nbrPresets,
  callbacks,
}: {
  loading: boolean
  preset: TPreset
  nbrPresets: number
  callbacks: TPResetOptionsCallbacks
}) {
  const { t, langKeys } = useTranslate()

  return (
    <MCard border boxShadow={false}>
      <CardContents $loading={loading}>
        <div>
          <MText variant="heading3">
            <InternalLink
              to={Routes.SettingsPresetsNoiseEdit.location(preset.id)}
            >
              {preset.name}
            </InternalLink>
          </MText>

          <div>
            {preset.number_of_homes +
              " " +
              t(langKeys.home, {
                count: preset.number_of_homes,
              }).toLocaleLowerCase()}
          </div>
        </div>

        <div>
          <PresetOptions
            preset={preset}
            nbrPresets={nbrPresets}
            {...callbacks}
          />
        </div>
      </CardContents>
    </MCard>
  )
}

const Cell = styled.div<{ $loading: boolean }>`
  opacity: ${({ $loading }) => ($loading ? "50%" : "100%")};
`

const CardContents = styled(Cell)`
  display: flex;

  & :first-child {
    flex-basis: 100%;
  }
`

const NoRentalUnitsBox = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.XS3};
`

const MenuContentBox = styled(MText)`
  display: flex;
  gap: ${spacing.XS};
  align-items: center;
  justify-content: center;

  & svg {
    height: 16px;
    width: 16px;
  }
`
